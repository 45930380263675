<template>
  <div class="about fill-height">
    <v-main
      id="main"
      class="tab-section fill-height"
      data-up="sub-header"
      data-left="categories"
      data-right="filters"
      :style="`padding-top:${headerHeight}px`"
    >
      <div class="content-center">
        <div>

          <h2 class="display-1 black--text mb-6">
            Quant a
          </h2>

          <p>
            La base de dades de legislació alimentària és un compendi de la normativa
            comunitaria, estatal i autonòmica.
          </p>

          <p>
            Es complementa amb enllaços a seccions de les webs de l’AECOSAN, de la
            Comissió Europea i del Ministeri d’Agricultura, que ajuden a interpretar les normes.
            Trobareu aquests enllaços en la nostra secció web
            <a href="http://acsa.gencat.cat/ca/actualitat/legislacio/" target="_blank">Legislació</a>.
          </p>

        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
import Btn from '@/components/Btn';
import { mapState } from 'vuex';

export default {
  name: 'About',
  components: { Btn },
  computed: mapState( 'app', [ 'headerHeight' ])
}
</script>
